window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

var csrfToken = document.querySelector('meta[name="csrf-token"]');
if (csrfToken) {
    window.csrfToken = csrfToken.content;
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

var apiToken = document.querySelector('meta[name="api-token"]');
if (apiToken) {
    window.apiToken = apiToken.content;
    window.axios.defaults.headers.common.Authorization = 'Bearer '+ apiToken.content;
} else {
    console.error('API token not found: https://laracasts.com/discuss/channels/laravel/how-to-pass-api-token-in-header');
}
