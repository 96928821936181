var $body = $('body');

function openTextField($parentIndex, $className) {

  $parent = $($parentIndex);

  $parent.toggleClass('openTextField');

  if($parent.hasClass('openTextField')) {

      $parent.find('.inative').each(function(e){
        $(this).removeAttr('disabled');
      });

  } else {

      $parent.find('.inative').each(function(e){
        $(this).attr('disabled', true);
      })

  };

 if($className == 'open_field_save') {

   //Salvar.

 }

};

$body.on('click', '.open_field, .open_field_cancel, .open_field_save', function(){

  $parentIndex = $(this).parents('.content-editable');
  $className = $(this).attr('class');
  openTextField($parentIndex, $className);

});

$body.find('.inative').each(function(){
  autosize(this);
});


/* EDITING */
/* -----------------------------  */
$(document).on('click', '.item-editing', function() {

  var $this = $(this);
  var $parent = $this.closest('.item-editable');

  $parent.toggleClass('editing');

});

$(document).on('click', '.item-removing', function() {

  var confirm = window.confirm('Deseja remover este item?');
  if(!confirm) { return false; }

  var $this = $(this);
  var $parent = $this.closest('.item-editable');

  $parent.remove();

});
