/* POPOVER */
/* -----------------------------  */
$(document).on('mouseenter', '.my-popover', function() {
  $(this).find('.my-popover-text').addClass('show');
});

$(document).on('mouseleave', '.my-popover', function() {
  $(this).find('.my-popover-text').removeClass('show')
});

/* EDITING */
/* -----------------------------  */
$(document).on('click', '.item-editing', function() {

  var $this = $(this);
  var $parent = $this.closest('.item-editable');

  // $parent.toggleClass('editing');

});

function disableTravelFormFields($form) {
    $form.find('input, textarea, select').each(function findInput() {
        const $field = $(this);
        $field.attr('disabled', 'disabled');
    });
}

function enableTravelFormFields($form) {
    $form.find('input, textarea, select').each(function findInput() {
        const $field = $(this);
        $field.removeAttr('disabled');
    });
}

$(document).ready(function editableSectionDates() {
    if ($('#item-editable-buttons-save').length) {
        $('#item-editable-buttons-save').click(function saveEditableDateSection(e) {
            e.preventDefault();
            e.stopPropagation();
            const $form = $('#sections');
            const formData = $form.serialize();
            const formAction = $form.attr('action');
            disableTravelFormFields($form);
            // const confirmed = confirm('Você tem certeza?');
            // if (confirmed === true) {
            if (formAction) {
                window.axios.request({
                    url: formAction,
                    method: 'put',
                    data: formData,
                })
                .then(function saveSectionResponse(response) {
                    window.toastr.success(response.data.message || (response.message || 'Alterado com sucesso'));
                    $.each(response.data, function eachSections(i, section) {
                        if ($(`.sections-${i}-date`).length) {
                            $(`.sections-${i}-date`).text(section.date_formatted);
                        }
                        if ($(`.sections-${i}-key-period`).length) {
                            $(`.sections-${i}-key-period`).text(section.period);
                        }
                    });
                    $('.item-cancel').trigger('click');
                    enableTravelFormFields($form);
                })
                .catch(function saveSectionError(error) {
                    window.toastr.error(error.request.message || (error.message || 'Houve algum problema por favor tente novamente mais tarde.'));
                    enableTravelFormFields($form);
                });
            }
            // } else {
            //     enableTravelFormFields($form);
            // }
        });
    }
});

$(document).on('click', '.item-cancel', function() {

  var $this = $(this);
  var $parent = $this.closest('.item-editable');

  $parent.find('.item-editable-content').each(function(){

      var oldValue = $(this).text();
      var input = $(this).next('input, select, textarea');

      $(input).val(oldValue);

  })

  $parent.toggleClass('editing');

});

$(document).on('click', '.item-removing', function() {

  var confirm = window.confirm('Deseja remover este item?');
  if (!confirm) {
    return false;
  }

  var $this = $(this);
  var $parent = $this.closest('.item-editable');

  $parent.remove();

});

/* RADIO FUNCTIONS */
/* -----------------------------  */
function checkRadioAction(el) {

  var $className = $(el).attr('id');
  var $name = $(el).attr('name');
  var $elements = $('div[data-' + $name + ']');

  $elements.hide();
  $('.' + $className).show();

};

$(document).on('change', 'input[name="trip-choice"]', function() {
  checkRadioAction(this);
});

/* DASHBOARD */
/* -----------------------------  */
// Open the form process approval and process avaliation
$(document).on('click', '.open-form', function() {

  var $this = $(this);
  var $form = $this.data('form');
  var $parent = $this.closest('.travel-dash-item');

  $parent.toggleClass('open-' + $form);

});

// Open the small menu to duplicate or delete a process
$(document).on('click', '.header-menu-button', function() {

  var $this = $(this);
  var $parent = $this.closest('.header');

  $parent.toggleClass('header-menu-open');

});

/* CLONE FUNCTION */
/*
  CLONAR DE - data-from
  PARA - data-to
*/
$(document).on('click', '.clone', function() {

  var cloneFrom = $(this).data('from'),
    cloneTo = $(this).data('to'),
    limit = $(this).data('limit'),
    counterClasses = $(this).data('count'),
    currentCloneds = $(cloneTo).find('.cloned').length;

  if (limit) {
    if (currentCloneds >= limit) {
      return false;
    }
  };

  var clone = $(cloneFrom).clone();

  clone = clone.removeClass('block-item-clonable');

  clone.addClass('cloned');
  clone.find('.clone-remove').removeAttr('style');
  clone.find('input, select, textarea').val("").attr('checked', false).end();

  //Exceções...
  clone.find('.upload-extensao').html("Enviar arquivo").end();

  //..
  var cloneClassIndex = cloneFrom.replace('.block-item-clonable', '');
  var incID = $(cloneTo).find(cloneClassIndex).length;

  clone.find('*').each(function(index, element) {

    if (element.name) {
      element.name = element.name + '[' + incID + ']';
    };

    if (element.id) {

      var id = element.id;
      var newId = element.id + '-' + incID;
      var label = $(element).closest('.clonable-item').children('label[for="' + element.id + '"]');

      element.id = newId;
      //Se houver uma label com for, perto do elemento. trocar o for pela referencia do ID.
      if ($(label).attr('for')) {
        $(label).attr('for', newId);
      };

    };

  });

  $(cloneTo).append(clone);

  cloneCounting(counterClasses);

});

function cloneCounting(counterClasses) {

  $(counterClasses).each(function(e) {

    var CloneCount = $(this).find('.clone-count');

    if (CloneCount.length) {
      CloneCount.html(parseInt(e + 1));
    }

  });

};

// Remove exclusivamente items clonados.
$(document).on('click', '.clone-remove', function() {

  var cloneToRemove = $(this).data('clone-to-remove');

  $(this).parents(cloneToRemove).remove();
  cloneCounting(cloneToRemove);

});

//Remove qualquer item na página.
$(document).on('click', '.remove-item', function() {

  var elementId = $(this).data('remove-item');
  $(elementId).remove();

});

// Show itens travel plan.
$(document).on('change', '.choose-item-travel', function() {

  var $item = $('.' + $(this).val());
  $(this).is(':checked') ? $item.show() : $item.hide();

});

//Change input.
$(document).on('change', 'input.file-upload', function() {

  var name = this.files[0].name;
  $(this).parent('.file-wrapper').find('.upload-extensao').html(name);

});

$(document).ready(function() {

  // Masks
  $('.cpf').mask('000.000.000.00');

  $('.choose-item-travel').each(function(i, item) {

    if ($(item).is(':checked')) {
      $('.' + $(this).val()).show();
    }

  })

});

// Modals
$(document).on('click', '.open-modal', function() {

  var modalId = $(this).data('id'),
    modal = '#' + modalId;

  $(modal).fadeIn('linear');

  $(document).on('click', '.close-modal', function() {
    $(modal).fadeOut('linear');
  });

  $(window).click(function(e) {
    if (e.target.id == modalId) {

      $(modal).fadeOut('linear');

    }
  });

});

$(document).ready(function() {

  // FAB`s on scroll
  (function() {

    var $list = $(".list");
    var $listButton = $(".list-open");
    var $fab = $(".fab");
    var $windowHeight = $(window).height();

    if ($fab.length > 0) {

      $(window).on('scroll', function() {

        var $footerTop = document.getElementsByTagName('footer')[0].offsetTop;
        var $scrollTop = $(this).scrollTop();

        if (($scrollTop + $windowHeight) > $footerTop) {
          $list.css('position', 'absolute');
          $fab.css('position', 'absolute');
          $listButton.css('position', 'absolute');
        } else {
          $list.css('position', 'fixed');
          $fab.css('position', 'fixed');
          $listButton.css('position', 'fixed');
        }
      });
    }
  }());

});

// Form validator
$("#step-2, #tab-3, #form-travel-plan-items").validate();
$(document).ready(function sendRequestQuotation() {
  if ($('#request-quotation').length) {
    $('#request-quotation').submit(function getRequestQuotation(e) {
      e.preventDefault();
      e.stopPropagation();

      const $form = $(this);
      const formData = $form.serialize();
      const formAction = $form.attr('action');

      if (formAction) {
        $form.hide();
        $('#request-quotation-loading').show();

        // Do the API call to create the Request
        window.axios.request({
          url: formAction,
          method: 'post',
          data: formData,
        })
        .then(function saveSectionResponse(response) {
          window.toastr.success(response.data.message || (response.message || 'Cotação solictada com sucesso'));

          if (!response.data.item.tms_solicitation) {
            throw 'Solicitação não foi criada';
          }

          const tmsSolicitation = response.data.item.tms_solicitation;
          const isOffline = tmsSolicitation.status_viagem === 'COT';

          if (isOffline) {
            $('#tms-solicitation-data_solicitacao')
              .html(response.data.item.tms_solicitation.data_solicitacao_formatted);

            $('#warning-quotation-offline').show();
          }

          // Processes the HTML spans with de data
          for (key in tmsSolicitation) {
            if (tmsSolicitation.hasOwnProperty(key)) {
              $el =  $(`#tms-solicitation-info-${key}`);
              if ($el.length > 0) {
                $el.html(tmsSolicitation[key]);
              }
            }
          }

          $('.warning').hide();
          $('#request-quotation-loading').hide();
          $('#tms-solicitation-info').show();
          $('#tms-solicitation-cancelled').show();
          if (!isOffline) {
            // If not offline shows the buttons to start the SSO process
            $('.display-can-select-quote').show();
          }
        })
        .catch(function saveSectionError(error) {
            window.toastr.error((error.request
              ? error.request.message
              : (error.message || 'Houve algum problema por favor tente novamente mais tarde.'
            )));

            // Brings the form back
            $form.show();
            $('#request-quotation-loading').hide();
        });
      }
    });
  }
});

$(document).ready(function sendRequestQuotation() {
  if ($('#request-sso-login').length) {
    var loading = false

    $('#request-sso-login').submit(function getRequestQuotation(e) {
      e.preventDefault();
      e.stopPropagation();

      if (loading) {
        return false;
      }

      const $form = $(this);
      const formAction = $form.attr('action');

      if (formAction) {
        $('#request-sso-login').hide();
            $('#request-sso-login-loading').show();
        loading = true

        window.axios.request({
            url: formAction,
            method: 'get',
        })
        .then(function saveSectionResponse(response) {
            if (response.data.url) {
              window.toastr.success(response.data.message ||
                (response.message || 'Conexão estabelecida. Abrindo pagina do TMS'));

              setTimeout(function () {
                loading = false
                $('#request-sso-login').show();
                $('#request-sso-login-loading').hide();

                window.open(response.data.url, '_blank');
              }, 2000);
            } else {
              window.toastr.error(
                error.request.message
                || (error.message
                  || 'Houve algum problema por favor tente novamente mais tarde.'));

              loading = false
              $('#request-sso-login').show();
              $('#request-sso-login-loading').hide();
            }
        })
        .catch(function saveSectionError(error) {
            window.toastr.error(
              error.request.message
              || (error.message
                || 'Houve algum problema por favor tente novamente mais tarde.'));

            loading = false
            $('#request-sso-login').show();
            $('#request-sso-login-loading').hide();
        });
      }
    });
  }
});

$(document).ready(function checkUserSelectionOnTMS() {
  if ($('#check-user-selection').length) {
    let loading = false;

    $('#check-user-selection').click(function getCheckUserSelection(e) {
      e.preventDefault();
      e.stopPropagation();

      if (loading) {
        return false;
      }

      const $this = $(this)
      const url = $this.attr('href');url
      const $loadingEl = $('#check-user-selection-loading')

      if ($loadingEl) {
        $loadingEl.show();
        $this.addClass('disabled')
        loading = true;
      }

      if (url) {
        window.axios.request({
            url: url,
            method: 'get',
        })
        .then(function saveSectionResponse(response) {
          setTimeout(function () {

            window.location.reload(true);
          }, 500)

          window.toastr.success('Item atualizado.');

          $loadingEl.hide();
          loading = false
          $this.removeClass('disabled')
          // if (response.data.url) {
              // window.open(response.data.url, '_blank');

          // }
        })
        .catch(function saveSectionError(error) {
          // window.toastr.error(
          //   error.request.message
          //   || (error.message
          //     || 'Houve algum problema por favor tente novamente mais tarde.'));

          $loadingEl.hide();
          loading = false
          $this.removeClass('disabled')
        });
      }
    });
  }
});

$(document).ready(function checkTmsSolicitationCancelled() {
    if ($('#tms-solicitation-cancelled').length) {
        let loading = false;

        $('#tms-solicitation-cancelled').click(function getTmsSolicitationCancelled(e) {
            e.preventDefault();
            e.stopPropagation();

            if (loading) {
                return false;
            }

            const $this = $(this);
            const url = $this.attr('href');
            const $loadingEl = $('#tms-solicitation-cancelled-loading');

            if ($loadingEl) {
                $loadingEl.show();
                $this.addClass('disabled');
                loading = true;
            }

            if (url) {
                window.axios.request({
                    url: url,
                    method: 'put',
                })
        .then(function saveSolicitationResponse() {
            setTimeout(function saveSolicitationReload() {
                window.location.reload(true);
            }, 500);

            window.toastr.success('Item atualizado.');

            $loadingEl.hide();
            loading = false;
            $this.removeClass('disabled');
        })
        .catch(function saveSectionError(error) {
            window.toastr.error(
            error.request.message
            || (error.message
              || 'Houve algum problema por favor tente novamente mais tarde.'));
            $loadingEl.hide();
            loading = false;
            $this.removeClass('disabled');
        });
            }
        });
    }
});
