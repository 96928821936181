try {
  var readyNotifications = false;
  var socket = io(window.location.origin + '/', { secure: true });
  var $user_id;
  var $listNotifications = $("#listNotifications");
  var $anchorNotifications = $("#notificationsBellAnchor");
  var $clearNotifications = $("#clearNotifications");
  var $warning = $(".socket-warning");

  /* VERIFICA O NAVEGADOR PARA SIMULAR DIFERENTES TIPOS DE USUÁRIOS (remover isso) */
  var isFirefox = typeof InstallTrigger !== 'undefined';
  var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
  var isChrome = !!window.chrome && !!window.chrome.webstore;

  //Recebe o ID para criar um canal de comunição no arquivo `index.js`
  //O campo input[data-socketID] é quem recebe o `id` do usuário ou tipo de usuário.
  $user_id = parseInt($("input[data-socketID]").val());

  //console.log('$user_id')
  //console.log($user_id)

  if ($user_id) {
    // socket.join($user_id);
    socket.emit("join", { msg: 'start', user_id: $user_id });

    function stopEvent(e) {
      e.stopPropagation();
      e.preventDefault();
    }

    // Let's disable the notification button for now
    $anchorNotifications.on('click', stopEvent)

    /***
      RECEBE O SINAL ASSIM QUE O SERVIDOR É INICIADO
      E ENVIA UM SINAL AO JOIN PARA ATUALIZAR OS REGISTROS.
    ****/
    socket.on("start", function(data){
        socket.emit("join", { msg: 'start', user_id: $user_id });
    });

    var newNotifications = [];
    socket.on("new_msg", function(data) {
        $listNotifications.html("");
        $warning.addClass("none");
        newNotifications = [];

        for (var i = 0; i < data.msg.length; i++)
        {
          try {
              // //console.dir(data.msg[i]);
              var $id = data.msg[i].id;
              var $msg = data.msg[i].msg;
              var $data = data.msg[i].created_at;
              var $id_user = data.msg[i].id_user;
              var $url = data.msg[i].url;

              var date = new Date($data);

              var dateConvert = (date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear());

              var $b = "<span class='notifications-date'><i class='fa fa-calendar-check-o' aria-hidden='true'></i>"+dateConvert+"</span>"
              var $a = "<a href='" + $url + "' data-id='" + $id + "' class='update_status'>" + $msg + $b + "</a>";
              $listNotifications.prepend("<li>" + $a + "</li>");
              updateNote();

              if (data.msg[i].new) {
                newNotifications.push($id);
              }
          } catch(err) {
            //console.log(err)
          }
        }

        if (newNotifications.length > 0) {
          label = newNotifications.length >= 99 ? newNotifications.length + '+' : newNotifications.length;
          $warning.removeClass("none").html(label);
        }

        if (data.msg.length === 0) {
          $clearNotifications.hide();
          $listNotifications.prepend("<li class=\"no-notifications\">Nenhuma notificação nova</li>");
        } else {
          $clearNotifications.show();
        }

        readyNotifications = true
        // Let's enable the click on the bellAnchor and remove the opacity limter if is there
        $anchorNotifications.off('click', stopEvent);
        if ($anchorNotifications.hasClass('opacity10')) {
          $anchorNotifications.removeClass('opacity10');
        }
    });

    //Update
    function updateNote() {

      $('.update_status').click(function(e){

        $dt_id = $(this).data("id");
        socket.emit("update note", { id: $dt_id, id_user: $user_id });
        window.location.href = $(this).attr('href');
      });

    };

    // Lets add An event handler that will mark the notifications as old as the user shows the notification list
    $('.notifications').click(function (e) {
      if (!readyNotifications) {
        return;
      }

      e.stopPropagation();
      e.preventDefault();

      var $this = $(this);
      var notificationList = $this.find('.notifications-ul, .notifications-ul ul');
      notificationList.show();

      $('body').toggleClass('open-notification-list');

      $('body.open-notification-list').click(function (e) {
        $('body').removeClass('open-notification-list');

        socket.emit("set-notices-as-old", { ids: newNotifications, id_user: $user_id });

        /**
         * Optimistic update
         */
        notificationList.hide();
        newNotifications = [];
        $warning.addClass("none").html('');
      });
    });

    // Set the handler for the click on the clearNotification
    $clearNotifications.on('click', function () {
      //console.debug('sending clear-all signal on socket for user #' + $user_id)
      socket.emit("clear-all", { id_user: $user_id });
    });

    socket.on("clear-all-success", function(data) {
      //console.debug('Received clear-all-success, so we will reread the notifications for user #' + $user_id)

      socket.emit("join", { msg: 'start', user_id: $user_id });
      $warning.addClass("none").html('');
    });
  }
} catch (e) {
    //console.log('Houve algum erro', e);
}
