$('.dash-slider').slick({

    slidesToShow: 6,
    slidesToScroll: 6,
    dots: true,
    arrows: true,
    infinite: false,

});

$(document).ready(function() {
    $('.dash-slider').each(function () {
        const $this = $(this);
        const dashItems = $this.find('.slick-slide');

        const lastActive = $this.find('.slick-slide.task_active:last').first();
        const lastActiveIndex = dashItems.index(lastActive);

        if (lastActiveIndex != -1) {
            this.slick.slickGoTo(lastActiveIndex);
            return;
        }

        const firstPending = $this.find('.slick-slide.task_pending').first();
        const firstPendingIndex = dashItems.index(firstPending);

        if (firstPendingIndex != -1) {
            this.slick.slickGoTo(firstPendingIndex);
            return;
        }

        this.slick.slickGoTo(dashItems.length);
    });

    $('.slider-loader').fadeOut('linear');
});
