function initSelectize (index) {
    var $this = $(this);

    //Grab some variables
    var options = {
        create: $this.attr('data-create') !== undefined ? true : false,
        persist: false,
        delimiter: ',',
        openOnFocus: true,
        searchField: $this.attr('data-search-field') !== undefined
            ? [$this.attr('data-value-field') || 'value', $this.attr('data-label-field') || 'label']
            : ['value', 'label'],
        valueField: $this.attr('data-value-field') !== undefined ? $this.attr('data-value-field') : 'value',
        labelField: $this.attr('data-label-field') !== undefined ? $this.attr('data-label-field') : 'label',
        onChange: function(e) {
            if ($this.attr('data-event') !== undefined) {
                window.EventHub.fire($this.attr('data-event'), e);
            }
        },
    };

    if ($this.attr('data-items') !== undefined) {
        options['items'] = JSON.parse($this.attr('data-items'));
    }

    if ($this.attr('data-data') !== undefined) {
        options['options'] = JSON.parse($this.attr('data-data'));
    }

    if ($this.attr('data-max')) {
      options.maxItems = $this.attr('data-max');
    }

    if ($this.attr('data-async') !== undefined) {
        options.load = function(query, callback) {
            const payload = { api_token: $('meta[name="api-token"]').attr('content') || null };
            var loadQuery = {};

            if (!query.length) {
                return callback();
            }

            if ($this.attr('data-load-query')) {
                try {
                    loadQuery = JSON.parse($this.attr('data-load-query'))
                } catch (e) {
                    // Cound no parse the query
                }
            }

            loadQuery = $.extend(true, payload, loadQuery);

            $.ajax({
                url: $this.attr('data-load-url') + '/' + encodeURIComponent(query),
                data: loadQuery,
                type: 'GET',
                error: function() {
                    callback();
                },
                success: function(res) {
                    const selectize = $this[0].selectize;

                    selectize.clear(1);

                    for (index in res.slice(0, 20)) {
                        selectize.addOption(res, 1);
                    }

                    // selectize.blur();
                    // selectize.focus();

                    callback(res.slice(0, 20));
                }
            });
        };
    }
    // JOF-295 -- allows a extended options for the selectize options
    else if ($this.attr('data-extended-options') !== undefined) {
        var extendedOptions = [];
        var initialOptions = [];

        if (this.options.length) {
            for (var i = 0; i <= this.options.length - 1; i++) {
                initialOptions.push({
                    value: this.options[i].value,
                    label: this.options[i].text,
                })
            }
        }

        try {
            extendedOptions = JSON.parse($this.attr('data-extended-options')) || []

            options.load = function(query, callback) {
                const selectize = $this[0].selectize;
                var options = initialOptions;
                selectize.clear(1);
                selectize.clearOptions();

                if (query.length) {
                    options = initialOptions.concat(extendedOptions)
                }

                return callback(options);
            };
        } catch (e) {
            // Cound no parse the options so let's do nothing
            // console.error('error-data-extended-options')
            // console.error(e)
        }
    }

    function addListenerForTarget (targetSelector) {
        const $target = $(targetSelector);
        var originalTargetQuery = {};

        if ($target.attr('data-load-query')) {
            try {
                originalTargetQuery = JSON.parse($target.attr('data-load-query'))
            } catch (e) {
                // Cound no parse the
            }
        }

        if ($target.length) {
            $this.on('change', function (e) {
                const value = e.target.value;
                var query = $.extend(true, {}, originalTargetQuery);
                var key = $this.attr('data-update-key') || $this.attr('name');

                ($target[0]).selectize.clear();
                ($target[0]).selectize.clearOptions();

                if (!value) {
                    $target.attr('data-load-query', JSON.stringify(query));
                    return;
                }

                query[key] = value;

                $target.attr('data-load-query', JSON.stringify(query));
            });
        }
    }

    if ($this.attr('data-update-target') != false) {
        var targetSelectors = $this.attr('data-update-target');
        try {
            targetSelectors = JSON.parse(targetSelectors)
        } catch (e) {
            // Cound no parse the
        }

        var i;
        if (targetSelectors instanceof Array) {
            for (i = targetSelectors.length - 1; i >= 0; i--) {
                addListenerForTarget(targetSelectors[i]);
            }
        } else {
            addListenerForTarget(targetSelectors);
        }
    }

    $this.selectize(options);

}

$(function() {
    $('select.selectize').each(initSelectize);

    const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            var newNodes = mutation.addedNodes; // DOM NodeList

            if (newNodes !== null) {
                var $nodes = $(newNodes);

                $nodes.each(function() {
                    var $node = $(this);

                    if ($node.hasClass('selectize')) {
                        initSelectize(this)
                    }
                });
            }
        });
    });

    const config = {
        attributes: true,
        childList: true,
        characterData: true
    };

    const $targets = $('.tab,.selectize-watcher');

    $targets.each(function (index, target) {
        observer.observe(target, config);
    })
});
