$(document).ready(function() {
    $('#international_document_true').change(function() {
        if ($(this).is(':checked')) {
            $('#document').unmask();
        }
    });
    $('#international_document_false').change(function() {
        if ($(this).is(':checked')) {
            $('#document').mask('99.999.999/9999-99');
        }
    });
});
