var $consultantFilterLi = $('.consultants-filter-specialty li');

$(document).ready(function() {

  if (!$('.consultants-filter-area-profissional input:checkbox').filter(':checked').length > 0) {
      $consultantFilterLi.show();
  } else {
    filterSpecialty();
  }
  
  // Cleaner
  $('.filter-clear').click(function() {

    var filterName = $(this).attr('data-name');

    if (filterName == 'area-profissional') {
      removeSpecialtyChecked();
    }

    $('.consultants-filter-' + filterName).find(':checked').removeAttr('checked');

  });

  // Remove all the checked checkbox in Specialty
  function removeSpecialtyChecked() {

    $consultantFilterLi.each(function() {
      $(this).find('input:checkbox').removeAttr('checked');
    });

    $consultantFilterLi.show();

  }

  // Remove all the checked checkbox in Specialty li with display none
  function removeLiChecked() {
    $consultantFilterLi.each(function() {
      if ($(this).css("display") == "none") {
        $(this).find('input:checkbox').removeAttr('checked');
      }
    });
  }

  // Function to filter Area with Specialty
  function filterSpecialty() {

    $consultantFilterLi.hide();

    $('.consultants-filter-area-profissional').find('input:checked').each(function() {
      var className = $(this).attr('rel');

      $consultantFilterLi.each(function() {
        if ($(this).hasClass(className)) {
          $(this).show();
        }
      });

    });
  }

  // Filter Area with Specialty
  $('.consultants-filter-area-profissional input:checkbox').click(function() {

      removeLiChecked();

      if (!$('.consultants-filter-area-profissional input:checkbox').filter(':checked').length > 0) {
          removeSpecialtyChecked();
      } else {
        filterSpecialty();
      }
  });

});



