if ($('#landing').length > 0) {

  $(document).ready(function() {

    $("#banner").find(".animated").each(function(i) {

      var $this = $(this);
      var $t = function(i) {

        setTimeout(function() {

          $animate = $this.data("animated");
          $this.addClass($animate);

        }, 200 * i);

      };

      new $t(i);

    })

    function debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this,
          args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    };

    var animeScroll = debounce(function() {

      $("section").each(function() {

        var $section = $(this),
            $top = $section.offset().top,
            $height = $section.outerHeight();

        if (parseInt($top) < parseInt($height / 1.2)) {

          $section.find(".animated").each(function(i) {

            var $this = $(this);
            var $delay = 250;

            var $t = function(i) {

              setTimeout(function() {

                $animate = $this.data("animated");
                $this.addClass($animate);

              }, $delay * i);

            };

            new $t(i);

          });

        };

      });
    }, 10);

    // var bannerFixed = function(element) {

    //   var scrollTop = $(element).scrollTop(),
    //     bg = $('.slide-bg');

    //   if (scrollTop < 900) {
    //     $('.slide-bg').css('top', +scrollTop + 'px');
    //   }
    // }

    $("body").scroll(function() {
      animeScroll();
      // bannerFixed(this);
    });


    // jQuery.scrollSpeed(90, 10);

  });
}