$(document).ready(function() {

  $('.file-wrapper input[type="file"]').not('.file-upload').change(function(e) {
    $this = $(this);

    $this.next().next('.upload-extensao').html($this.val().replace('C:\\fakepath\\', ''));

  });

});

$(document).ready(function() {
  var fileInput = $(".file-upload"),
    btnFile = $(".input-file-trigger"),
    theReturn = $(".files");

  fileInput.change(function(e) {

    $(theReturn).html(' ');

    var files = this.files;

    if (files.length > 3) {

      $(this).parents('.form-group').find('#file-upload').val('');

      var msg = fileInput.attr('data-msg') || ' Faça upload de no máximo 300 arquivos!'
      theReturn.append(`<p class="alert-red">${msg}</p>`);

    } else {

      for (var i = 0; i < files.length; i++) {
        theReturn.append(`<p class="inline file-return">${files[i].name}<br></p>`);
      }

      var msg = fileInput.attr('data-clear-msg') || 'Limpar'
      theReturn.append(`<a href="#!" class="remove-file link"><i class="fa fa-times " aria-hidden="true"></i> ${msg}</a>`);
    }
  });
})

$(document).on("click", ".ict .remove-file", function() {

  $(this).parents('.form-group').find('#file-upload').val('');
  $(".files").html(' ');

});
