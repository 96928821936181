function alternate(target, id){

  $(target).css('display','none');
  $(id).css('display','block');

};

$('.alternate').on("click", function(){

  $id = '#' + $(this).data('show');
  $target = '.' + $(this).attr('name');
  alternate($target, $id);

})
