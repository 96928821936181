function lookUpViacep(e) {
    const $this = $(this);
    const $targetInputs = $('.viacep-target');
    const regexCep = /^[0-9]{8}$/;

    var cep;

    function clearViacepTarget() {
        $targetInputs.find('.viacep-*').val('')
    }

    // Nova variável "cep" somente com dígitos.
    cep = $(this).val().replace(/\D/g, '');

    if (cep.length < 7) {
        clearViacepTarget();
        return
    }

    if (!regexCep.test(cep)) {
        clearViacepTarget();
        window.toastr.warning('CEP invalido');
    }

    $targetInputs.find('[class^="viacep-"]').val('...');

    $.getJSON("//viacep.com.br/ws/"+ cep +"/json/?callback=?", function(data) {
        if (!("erro" in data)) {
            for (key in data) {
                if (data.hasOwnProperty(key)) {
                    $targetInputs.find('.viacep-' + key).val(data[key]);
                }
            }
        } else {
             clearViacepTarget();
            window.toastr.error('CEP não foi encontrado');
        }
    });
}

function setViacepHandler() {
    // console.dir('setViacepHandler');
    removeViacepHandler()
    $('.viacep.cep').on('blur', lookUpViacep);
}

function removeViacepHandler() {
    // console.dir('removeViacepHandler');
    $('.viacep.cep').off('blur', lookUpViacep);
}

function countryRules(isNational) {
    const $viacepInput = $('input.viacep');
    const $targetInputs = $('.viacep-target');

    // Changes the CEP label
    var zipcodeLabel = '* Código Postal';
    if (isNational) {
        zipcodeLabel = '* CEP';
    }
    $viacepInput.siblings('label').text(zipcodeLabel);

    $mask = '99999-999';
    if (isNational) {
        $viacepInput.addClass('cep');
        $viacepInput.mask($mask);
        setViacepHandler();
    } else {
        removeViacepHandler();
        $viacepInput.unmask($mask);
        $viacepInput.removeClass('cep');
    }

    // var cidade and state as open fields
    if (isNational) {
        $targetInputs.find('.viacep-uf, .viacep-localidade').attr('readonly');
    } else {
        $targetInputs.find('.viacep-uf, .viacep-localidade').removeAttr('readonly');
    }
}

$(document).ready(function() {

    const $targetInputs = $('.viacep-target');
    window.app = window.app || {};

    setViacepHandler();

    function handleChange (e) {
        if (window.app.defaultCountry) {
            countryRules(window.app.defaultCountry.id == e.target.value);
        }
    }

    $('select.viacep-pais').on('change', handleChange);
});
