$('.btn-newshortlist').click(function() {
    $('.new-shortlist-field').toggleClass('none');
});

function modalAjaxSubmmit(e) {
    const $modal = $(this).parents('.float-modal');
    const bindedAjaxSubmit = ajaxSubmit.bind(this)

    bindedAjaxSubmit(e, function(response) {
        $modal.css("display", "none");

        window.toastr.success(response.data);

        setTimeout(function() {
            window.location.reload(1);
        }, 200);
    })
}

function ajaxSubmit(e, cb) {
    const $button = $(this);
    const $form = $button.parents('form');
    const formData = {};

    e.preventDefault();
    e.stopPropagation();

    $form.addClass('sending');
    $button.attr('disabled', 'disabled');

    $form.find('input, textarea, select').each(function findInput() {
        const $field = $(this);

        if ($field.attr('type') === 'radio') {
            if ($field.prop('checked')) {
                formData[$field.attr('name')] = $field.val();
            }
        } else {
            formData[$field.attr('name')] = $field.val();
        }

        $field.removeClass('error');
        $field.removeClass('has-error');
        const $formGroup = $field.parent('.form-group');
        $formGroup.removeClass('has-error');
        // $formGroup.find('.help-block').html('&nbsp;');
        // $field.attr('disabled', 'disabled');
    });

    if (((formData.action === 'vendor-delete-list') || (formData.action === 'vendor-delete-agency') || (formData.action === 'shortlist-delete-vendor')) && (formData.vendor)) {

        var r = confirm("Você tem certeza?");
        if (r == true) {
            if ($form.attr('action')) {
                window.axios.request({
                    url: $form.attr('action'),
                    method: 'post',
                    data: formData,
                })
                .then(function (response) {

                    // $($form).trigger("reset");

                    showResponse($form, $button, true);

                    if ((formData.action === 'vendor-delete-list') && (formData.vendor)) {
                        $('#vendor-' + formData.vendor).remove();
                    } else if ((formData.action === 'vendor-delete-agency') && (formData.vendor)) {
                        $('#vendor-' + formData.vendor + '-agency').remove();
                    } else if ((formData.action === 'shortlist-delete-vendor') && (formData.vendor)) {
                        $('#shortlist-' + formData.shortlist + '-vendor-' + formData.vendor).remove();
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        const response = error.response;
                        if (response.status == 422) {
                            var errors = response.data;
                            for (var field in errors) {
                                if (errors.hasOwnProperty(field)) {
                                    $errorInput = $form.find('[name="' + field +'"]');
                                    $errorInput.addClass('has-error');
                                    $formGroup = $errorInput.parent('.form-group');
                                    $formGroup.addClass('has-error');
                                    $formGroup.find('.help-block').text(errors[field]);
                                }
                            }
                            showResponse($form, $button, false);
                        }
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                });
            }
        } else {

          $button.removeAttr("disabled");

        }
    } else {
        if ($form.attr('action')) {
            window.axios.request({
                url: $form.attr('action'),
                method: 'post',
                data: formData,
            })
            .then(function (response) {
                // $($form).trigger("reset");

                showResponse($form, $button, true);

                if (((formData.action === 'vendor-insert-shortlist') && (formData.vendor)) ||
                    (formData.action === 'roster-insert-shortlist')) {
                    $('#show-shortlist-add').hide();

                    setTimeout(function() {
                        window.location.reload(1);
                    }, 1500);
                }

                if (cb) {
                    cb(response)
                } else {
                    window.toastr.success(response.data.message || (response.message || 'Alterado com sucesso'));
                }
            })
            .catch(function (error) {
                // The response out of the 2xx statuses
                if (error.response) {
                    const response = error.response;
                    if (response.status == 422) {
                        var errors = response.data;
                        for (var field in errors) {
                            if (errors.hasOwnProperty(field)) {
                                $errorInput = $form.find('[name="' + field +'"]');
                                $errorInput.addClass('has-error');
                                $formGroup = $errorInput.parent('.form-group');
                                $formGroup.addClass('has-error');
                                $formGroup.find('.help-block').text(errors[field]);
                            }
                        }
                        showResponse($form, $button, false);
                    }
                } else if (error.request) {
                    window.toastr.error(error.request.message || error.message);
                } else {
                    window.toastr.error(error.message);
                }
            });
        }
    }
}

function changeCheckbox(checkbox) {
    console.dir(checkbox.attr('data-vendor'));
    var $checkbox = checkbox;
    var $form = $checkbox.parents('form');
    var formData = {};
    $form.find('input, textarea, select').each(function findInput() {
        var $field = $(this);
        formData[$field.attr('name')] = $field.val();
    });
    if ($(checkbox).is(':checked')) {
        formData["checked"] = 1;
    } else {
        formData["checked"] = 0;
    }
    console.dir(formData);
    if ($form.attr('action')) {
        window.axios.request({
            url: $form.attr('action'),
            method: 'post',
            data: formData,
        })
        .then(function (response) {
            window.toastr.success(response.message || 'Alterado com sucesso');
        })
        .catch(function (error) {
            if (error.request) {
                console.error(error.request);
                window.toastr.error(error.request.message || error.message);
            } else {
                console.error('Error', error.message);
                window.toastr.error(error.message);
            }
        });
    }
}

function showResponse($form, $button, success) {
    $form.removeClass('sending');
    if (success == true) {
        $form.addClass('response');
    } else {
        $form.addClass('default');
    }

    setTimeout(function () {
        $button.removeAttr('disabled');
        $form.removeClass('response').addClass('default');
        $form.find('input, textarea').each(function () {
            $(this).removeAttr('disabled');
        });
    }, 2500);
}

$(document).ready(function() {
    $('.ajax-form').each(function(index) {
        $(this).find('button[type="submit"]').each(function() {
            $(this).on("click", ajaxSubmit);
        });
    });

    $('.modal-ajax-form').each(function(index) {
        $(this).find('button[type="submit"]').each(function() {
            $(this).on("click", modalAjaxSubmmit);
        });
    });

    $('a.submit-form').each(function(index) {
        $(this).on('click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            $(this).parents('form').submit();
        });
    });

    $('.change-flag').on('change', function(e) {
        var cfVal = $(this).val();

        if ($(this).is(':checked')) {
            var r = confirm("Deseja adicionar o fornecedor como ativo?");
        } else {
            var r = confirm("Deseja adicionar o fornecedor como inativo?");
        }

        if (r == true) {
            changeCheckbox($(this));
        } else {
            e.stopPropagation();
            e.preventDefault();
            if ($(this).is(':checked')) {
                $(this).prop('checked', true);
            } else {
                $(this).prop('checked', false);
            }
        }
    });

    $('#open-shortlist-add').click(function openShortlistAdd(e) {
        e.preventDefault();
        e.stopPropagation();
        $('#show-shortlist-add').show();
    });

    $('#close-shortlist-add').click(function openShortlistAdd(e) {
        e.preventDefault();
        e.stopPropagation();
        $('#show-shortlist-add').hide();
    });

    $('a.confirmable-link').click(function(e) {
        const target = e.target;
        const $this = $(this);
        const message = $this.data('message') || 'Você tem certeza?';

        if (!window.confirm(message)) {
            e.stopPropagation();
            e.preventDefault();
        }

        return;
    })

    $('a.confirmable-submit').click(function(e) {
        const target = e.target;
        const $this = $(this);
        const message = $this.data('message') || 'Você tem certeza?';

        e.stopPropagation();
        e.preventDefault();

        if (window.confirm(message)) {
            $(this).parent('form').submit();
        }

        return;
    });
});

$(document).on('click', '.show-experiences .edit, .show-experiences .save', function(){
  const $this = $(this);

  $this.parent('.show-experiences-actions').toggleClass('enable-save-link');
  $textarea = $this.parents('.show-experiences').find('textarea');

  $this.hasClass('edit') ? $textarea.attr('disabled', false) : $textarea.attr('disabled', true);

});

$(document).on('change', '#experience-current', function() {
  $experienceFinalDate = $('#experience-final-date');
  $experienceFinalDate.val('');
  this.checked ? $experienceFinalDate.attr('disabled','disabled') : $experienceFinalDate.attr('disabled', false);
});

$(document).on('click', '.remove-actuation-area a', function() {
  $(this).parents('.new-actuation-area').remove();
});

$(document).ready(function() {
  const $filter = $('.filter');

  $filter.each(function (index) {
    const $self = $(this);
    const target = $filter.attr('data-target')

    if (!target) {
      return;
    }

    var $target = $(target);
    const $options = $target.find('option');

    $self.on('change', function (e) {
      $target.parent().removeClass('disabled');
      $target.prop("disabled", false);

      $target.html($options.filter('[data-filter="' + $self.find(':selected').val()+ '"]'));
    });
  });
});


$(document).on('focus', '.selectize-input input', function() {

 var $parent = $(this).parents('.select');

  $parent.css('z-index','99');

  $(this).on('blur', function(){

      $parent.css('z-index','0');

  })
});
