function initDatePicker(i, dateElement) {
    const $el = $(dateElement);
    const $targetInput = $el.siblings('input[name="' + $el.data('target') +'"]');
    $el.datepicker({
        keyboardNavigation: false,
        forceParse: false,
        autoclose: true,
        locale: window.app.locale,
        format: {
            toDisplay: function (date, format, language) {
                var d = new Date(date);

                if (!isNaN( d.getTime() )) {
                    var userTimezoneOffset = d.getTimezoneOffset() * 60000;
                    var date = new Date(d.getTime() + userTimezoneOffset)

                    $targetInput.val(date.toISOString().substring(0, 10));
                    return moment(date).locale(window.app.locale).format('L');
                }

                $targetInput.attr('value', '');
                return ''
            },

            toValue: function (date, format, language) {
                var d = new Date(date);
                var userTimezoneOffset = d.getTimezoneOffset() * 60000;

                if (!isNaN( d.getTime() )) {
                    var date = new Date(d.getTime() + userTimezoneOffset);
                    return date;
                }

                d = new Date();
                return new Date(d.getTime() + userTimezoneOffset);
            }
        }
    }).on('clearDate', function (e) {
        $targetInput.attr('value', '');
    });

    if ($targetInput.val()) {
        $el.val(moment($targetInput.val()).locale(window.app.locale).format('L'))
    }
}

$(document).ready(function() {
    $('input.date').each(initDatePicker);

    const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            var newNodes = mutation.addedNodes; // DOM NodeList

            if (newNodes !== null) {
                var $nodes = $(newNodes);

                $nodes.each(function() {
                    var $node = $(this);

                    if ($node.hasClass('date')) {
                        initDatePicker(0, this)
                    }
                });
            }
        });
    });

    const config = {
        attributes: true,
        childList: true,
        characterData: true
    };

    const $targets = $('.tab');
    $targets.each(function (index, target) {
        observer.observe(target, config);
    })
});
