//Table
$('.table').not('.no-footable').footable();

$(document).ready(function() {
  $('.nav-tabs').find('a[href="#tab-1"]').click();

  $('button.previous-tab').click(function (e) {
    const $this = $(this);

    var selectedTab = $('.nav-tabs').find('a[data-toggle="tab"].tab-selected').parent('li');

    if (selectedTab) {
      var previousTab = $(selectedTab).prev();

      if (previousTab) {
          $(previousTab).find('a[data-toggle="tab"]').click();
      }
    }
  })

  // Creates clickable tr
  $('tr.clickable').on('click', function () {
    const href = $(this).attr('data-href')

    if (href) {
      window.location.assign(href);
    }
  });
});
