$('.open-modal').click(function() {

  var modalId = $(this).data('id'),
    modal = '#' + modalId;

  $(modal).css('display', 'block');

  $('.close-modal').click(function() {
    $(modal).css('display', 'none');
  });

  $(window).click(function(e) {
    if (e.target.id == modalId) {
      $(modal).css('display', 'none');
    }
  });

});
