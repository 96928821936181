$('.open-modal-cancel').click(function omc(event) {
    event.preventDefault();

    const data = $(this).data('cancel-id');
    const dataItem = $(this).data('cancel-item-id');
    const type = $(this).data('cancel-type');

    $('#cancel-id').val(data);

    $('#modal-cancel').each(function mc() {
        $(this).css('display', 'block');
    });

    if (type === 'travel') {
        var remove = $(this).data('remove');

        console.dir('cancel-remove', remove)
        $('#send-exclude').attr('action', `/travel/travelplans/${data}/remove`);

        if (remove) {
            console.log('hiding-fields')
            $('#cancel-form-fields').hide();
        }

        $('#send-cancel').attr('action', `/travel/travelplans/${data}/cancel`);
    } else if (type === 'travel-item') {
        $('#send-cancel').attr('action', `/travel/travelplans/${data}/items/${dataItem}/cancel`);
    } else {
        $('#send-exclude').attr('action', `/procurement/requests/${data}/exclude`);
        $('#send-cancel').attr('action', `/procurement/requests/${data}/cancel`);
    }
});

$('.close-modal-cancel').click(function cmc() {
    $(this).parents('.float-modal.modal-cancel').css('display', 'none');
});

$('.open-modal-review').click(function omr() {
    const id = $(this).data('request-id');

    $(`#modal-review-${id}`).each(function mr() {
        $(this).css('display', 'block');
    });
});

$('.close-modal-review').click(function cmr() {
    $(this).parents('.float-modal.modal-cancel').css('display', 'none');
});
