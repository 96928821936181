function bindTree(arr) {
    const disabledNodes = [];

    function mapNodes (node) {
      if (!node.enabled) {
        disabledNodes.push(node.id)
      }

      return {
        id: node.id,
        text: window.app && window.app.locale === 'pt-br' ? node.locale_title : node.title,
        children: node.children ? node.children.map(mapNodes) : []
      };
    }

    arr = arr.map(mapNodes)

    var total = $("i[data-three-total]"),
        //Lista as categorias.
        dataCatergory = $("ul[data-catergory]"),
        //Tag que chama o plugin.
        tree = $('.tree'),
        to = false,
        //Input que filtra os items
        find = $('input[data-three-find]');

    //Função que dá o parse para extrair os dados do model.
    function collect(model, id, checked) {

        var node = model[id], i, j;

        if( node.state && node.state.checked ) {

            checked.push({
              text:node.text,
              id:node.id
            });

        };

        for( i = 0, j = node.children.length; i < j; i++ ) {

            collect(model, node.children[i], checked);

        };

    };

    //Tira o check em um item específico.
    function uncheck_item(i) {

      $('a[data-id="'+i+'"]').click(function(){

          var $id = $(this).data("id");
          tree.jstree(true).uncheck_node($id);

      });

    };

    //Cria a lista para colocar em `códigos selecionados`.
    function createElement(a) {
      var l = "<li><a href='#!' data-id='"+a.id+"' class='fa fa-times-circle'></a><span>" + a.text + "</span></li>";
      dataCatergory.append(l);
      //Depois de criado atribui ao dataset a função que faz remover o check em um item específico.
      uncheck_item(a.id);

    };

    //Plugin tree.js
    var jstreeOptions = {
        core: {
            themes: {
                icons: false
            },
            'data' : arr
        },

        checkbox : { tie_selection : false },

        search: {
          show_only_matches: true
        },

        plugins: ["themes", "checkbox", "search"]
    }

    tree.jstree(jstreeOptions).on("check_node.jstree uncheck_node.jstree", function(e, data) {
        //Limpa o que já existe para receber os novos.
        dataCatergory.html(" ");

        var checked = [];

        //Pega os items checados, com os valores de id.
        var $items = $(this).jstree(true).get_checked();

        //Coloca dentro do input[data-three-id], os ids dos elementos selecionados.
        $('input[data-three-id]').val($items.join(','));

        //Calcula o total de itens selecionados, e coloca no atributo [data-three-total]
        total.html($(".tree").jstree(true).get_checked().length);

        //Pega os dados selecionados no modelo como são apresentados em texto.
        var Modeldata  = tree.jstree(true)._model.data;

        //Função que dá o parse para extrair os dados do model.
        collect(tree.jstree(true)._model.data, '#', checked)

        //O collet retorna o array checked com os elementos que estão selecionados, texto e id.
        checked.forEach(function(a){
            //Função que cria os elementos.
            createElement(a);
          })
     }).on("ready.jstree", function(e, data) {
        var initialCodes = $('input[data-three-id]').val();
        if (initialCodes) {
            initialCodes.split(',').map(setChecked)
        }

        tree.jstree(tree).disable_node(disabledNodes)
    });

    //Limpa os links selecionados
    $("a[data-clean]").each(function() {
       $(this).click(function() {
          //TIRA OS ITEMS SELECIONADOS
          tree.jstree(true).uncheck_all();
          //RESETA O TOTAL DE ITEMS
          total.html("0");
          //LIMPA OS LINKS SELECIONADOS
          dataCatergory.html(" ");
          //LIMPA O CAMPO ONDE OS ID`S ESTÃO.
          $('input[data-three-id]').val("");
       });
    });

    function debounce(func, wait, immediate) {
        // 'private' variable for instance
        // The returned function will be able to reference this due to closure.
        // Each call to the returned function will share this common timer.
        var timeout;

        // Calling debounce returns a new anonymous function
        return function() {
            // reference the context and args for the setTimeout function
            var context = this,
                args = arguments;

            // Should the function be called now? If immediate is true
            //   and not already in a timeout then the answer is: Yes
            var callNow = immediate && !timeout;

            // This is the basic debounce behaviour where you can call this
            //   function several times, but it will only execute once
            //   [before or after imposing a delay].
            //   Each time the returned function is called, the timer starts over.
            clearTimeout(timeout);

            // Set the new timeout
            timeout = setTimeout(function() {

                 // Inside the timeout function, clear the timeout variable
                 // which will let the next execution run when in 'immediate' mode
                 timeout = null;

                 // Check if the function already ran with the immediate flag
                 if (!immediate) {
                   // Call the original function with apply
                   // apply lets you define the 'this' object as well as the arguments
                   //    (both captured before setTimeout)
                   func.apply(context, args);
                 }
            }, wait);

            // Immediate mode and no wait timer? Execute the function..
            if (callNow) func.apply(context, args);
         };
    };

    //FILTRA OS ELEMENTOS NA COLUNA
    find.keyup(debounce(function () {
        var v = find.val();

        if (v && v.length < 5) {
          $('.js-tree-min-length').show();
          return;
        }

        $('.js-tree-min-length').hide();
        tree.jstree(true).search(v);
     }, 500));

    tree.bind('search.jstree', function () {
      $('.js-tree-loading').hide();
    })

    // Initially check based on the input value
    function setChecked(id){
        tree.jstree(true).check_node(id)
    };
}

function processAndBindTree() {
  var arr = window.localStorage.getItem('unspsc-codes');
  var created = window.localStorage.getItem('unspsc-codes-created');
  var startOfDay = new Date();
  startOfDay = startOfDay.setHours(1);

  if (arr && created && (new Date(created) > startOfDay)) {
    bindTree(JSON.parse(arr))
  } else {
    window.axios.get('/unspsc-codes').then(function (response) {
        window.localStorage.setItem('unspsc-codes', JSON.stringify(response.data));
        window.localStorage.setItem('unspsc-codes-created', new Date());

        bindTree(response.data)
    }).catch(function (error) {
        console.error(error)
    })
  }
}

// $(document).on('load', '.tree-unspsc-codes', processAndBindTree);
$(document).ready(processAndBindTree)

