$(document).ready(function () {
    $('.international_phone').each(function(i, telInput) {

        const $telInput = $(this);
        const $targetInput = $telInput.siblings('input[name="' + $telInput.data('target') +'"]');
        const $formGroup = $telInput.parents('.form-group')
        var mask;

        var resetTelInput = function() {
            $formGroup.removeClass("has-error");
        };

        // Let's init the input
        $telInput.intlTelInput({
            preferredCountries: ['br', 'us'],
            initialCountry: "auto",
            autoHideDialCode: false,
            // separateDialCode: true,
            autoPlaceholder: 'aggressive',
            geoIpLookup: function(callback) {
                $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
                    var countryCode = (resp && resp.country) ? resp.country : "";
                    callback(countryCode);
                });
            },
        });

        $telInput.intlTelInput("setNumber", $targetInput.val());

        function updateMaskOnInput() {
            var placeholder = $telInput.attr('placeholder');
            $telInput.unmask();

            if (placeholder) {
                // console.dir('apply mask')
                $telInput.mask(placeholder.replace(/[0-9]/g, "0"))
                var newMaxLLength =  parseInt($telInput.attr('maxlength'), 10) + placeholder.replace(/[0-9]/g,"").length
                $telInput.attr('maxlength', newMaxLLength)
            }
        };

        $telInput.blur(function() {
            resetTelInput();
            updateMaskOnInput();

            if ($.trim($telInput.val())) {
                if (!$telInput.intlTelInput("isValidNumber")) {
                    $formGroup.addClass("has-error");
                }

                $targetInput.val($telInput.intlTelInput("getNumber", intlTelInputUtils.numberFormat.E164));
            }
        });

        $telInput.on("countrychange", function(e, countryData) {
            updateMaskOnInput();
        });

        $telInput.on("keyup change", resetTelInput);

       function phoneNumberUtilsLoaded() {
            setTimeout(updateMaskOnInput, 200);
        };

        $.fn.intlTelInput.loadUtils("/js/intl-tel-utils.js", new Promise(function(resolve, reject) {
            resolve(phoneNumberUtilsLoaded());
        }));
    });
});
