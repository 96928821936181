// $('#banner-slider').slick({
// 
//   dots: true,
//   fade: true,
//   arrows: false,
//   autoplay: true,
//   autoplaySpeed: 5000
// 
// });


$(document).ready(function() {
  $("#banner-slider .text-center").css("opacity", "1");
  $(".slide-bg").css("opacity", "1");
});

$('.slider-agencias').slick({
  slidesToShow: 6,
  slidesToScroll: 6,
  arrows: false,
  fade: false,
  responsive: [{
      breakpoint: 1200,
      settings: {
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 3,
        infinite: true
      }
    },
    {
      breakpoint: 1024,
      settings: {
        dots: true,
        slidesToShow: 4,
        slidesToScroll: 3,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]

});

$('#services').slick({

  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        dots: true,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        dots: true,
        slidesToScroll: 1
      }
    }
  ]

});

$('.slider-agencias-text').slick({

  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  centerMode: false,
  swipe: false

});

function triggerBullet(i) {

  $('.active').removeClass('active');
  $('.slider-agencias-text .slick-dots button:eq(' + i + ')').click();
  $('.agencia:eq(' + i + ')').addClass('active');

};

$('.agencia').each(function(i) {

  var $index = i;
  $(this).click(function() {
    triggerBullet($index)
  })

});

triggerBullet(0);