$(document).ready(function () {
    // $('.money').mask("# ##0,00", { placeholder: "0,00", reverse: true});

    $('.money').each(function(i, moneyInput) {
        const $moneyInput = $(this);
        const $targetInput = $moneyInput.siblings('input[name="' + $moneyInput.data('target') +'"]');
        const mask = $moneyInput.attr('data-mask') || '# ##0,00'

        function pad(n, width, z) {
            z = z || '0';
            var filter = new RegExp("[" + z + "]*", "i"); // Let'sremove the first ocurrences of z
            n = n.replace(/[^0-9]/g, '').replace(filter, '') + '';

            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }

        $(this).val(pad($(this).val(), 3));
        $(this).html(pad($(this).html(), 3));

        $moneyInput.mask(mask, { placeholder: "0,00", reverse: true})

        $moneyInput.blur(function() {
            $targetInput.val($moneyInput.val().replace(/[^0-9]/g, ''));
        });

        $moneyInput.change(function () {
            const $this = $(this)

            $this.val(pad($this.val(), 3));
            $this.html(pad($this.html(), 3));
            $this.unmask();
            $this.mask(mask, { placeholder: "0,00", reverse: true});
        })
        $moneyInput.change();
    });
});
