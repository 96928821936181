$(document).ready(function(){
    $('.agency-select').change(function (e) {
        const $projectSelect = $('.filter-project-select');
        var filtered = [];

        if ($projectSelect) {
            const projects = JSON.parse($projectSelect.attr('data-projects'));
            var selectize = $projectSelect[0].selectize;

            if (e.target.value) {
                filtered = projects.filter(function (value) {
                    return value.id_agency == e.target.value
                })
            }

            selectize.clearOptions()
            selectize.addOption(filtered)
        }
    })
})