function calculateProduct(index) {
    const $this = $(this);
    var result = null;
    var useComma = false;

    const $target = $this.find('.product-result');

    function convertDecimals(string) {
        string = string.replace(/\s/g, '');

        if (string.indexOf(',') >= 0) {
            useComma = true;
            return string.replace(/\./g, '').replace(/\,/g, '.');
        }

        return string;
    }

    if ($target.length == 0) {
        return;
    }

    $this.find('.product-factor').each(function (i) {
        var $factorEl = $(this);
        var value = parseFloat(convertDecimals($factorEl.html() || $factorEl.val()), 10);

        if (!value || value == 0) {
            result = 0
            return;
        } else {
            if (result === null) {
                result = 1;
            }

            result = result * value;
        };
    });

    $target.each(function (i) {
        // var delimiter = useComma ? ',' : '.'
        var string = '--'

        if (result) {
            string = result.toFixed(2)
        }

        if (useComma) {
            string = string.replace(/\,/g, '').replace(/\./g, ',')
        }

        $(this).val(string)
        $(this).html(string)
    });
}

$(document).ready(function() {
    $productEls = $('.product-calculator')

    $productEls.each(calculateProduct);
    $productEls.on('change', calculateProduct);
});