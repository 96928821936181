function tabs(evt, $id) {
  const activeTabHash = '123!@#-active-tab-';
  $classActive = 'tab-selected';

  $(".tabs-content").css("display","none");

  $('.tabs-container').each(function(i) {
    var $this = $(this);
    var activeTab = null;

    if ($this.attr('data-keep-active-tab')) {
      activeTab = window.localStorage.getItem(activeTabHash + $this.attr('data-keep-active-tab'));
    }

    var countTabs = $this.find(".tabs-nav a").length;
    $this.find(".tabs-nav a").each(function(i) {
      var $tab = $(this);

      if ((activeTab !== null) && (i == activeTab) && $(this).hasClass("no-results")) {
        activeTab = countTabs - 1
      }

      // If there is a activeTab, but there is no result it will
      if (
        ((activeTab !== null) && (i == activeTab) && !$(this).hasClass("no-results"))
        || $(this).hasClass("tab-selected")
      ) {

        $('.'+$classActive).removeClass($classActive);
        $this.find(".tabs-content:eq("+i+")").css("display","block");
        $tab.addClass($classActive);
      } else {
        $this.find(".tabs-content:eq("+i+")").css("display","none");
      }
    });
  });

  $('.tabs-container').find('.tabs-nav').each(function() {
    $tabsNav = $(this);

    $tabsNav.find('a').each(function(e){

      $(this).click(function() {

        var $el = $(this);
        var $parent  = $el.parents('.tabs-nav');
            $parent = ($parent.parents('.tabs-container'));
        var $index = $el.parents('.tabs-nav');
            $i = ($index.find('a').index($el));

        if (!$el.hasClass('disabled')) {

          $('.'+$classActive).removeClass($classActive);
          $el.addClass($classActive);

          $parent.find('.tabs-content').css('display','none');
          $parent.find('.tabs-content:eq('+$i+')').css('display','block');

          $parent.find('.tabs-content .dash-item').each(function(i){
            if ($('.dash-slider').length > 0) {
              $('.dash-slider').get(i).slick.setPosition();
            }
          });

          if ($parent.attr('data-keep-active-tab')) {
            window.localStorage.setItem(activeTabHash + $parent.attr('data-keep-active-tab'), $i);
          }

          if ($("#bg-conditional").length > 0 ) {
              $("#bg-conditional").removeClass("gray")
          }

          if ($(this).attr("href") == "#tab-4"){

            if($("#bg-conditional").length > 0 ) {
                $("#bg-conditional").addClass("gray")
            }

          }

        }



      });
    });
  });

  $(document).ready(function(){
    setTimeout(function(){
      $(".tab-field-has-erros:eq(0)").parent().click();
    }, 100)

    setTimeout(function(){
      $("a.tab-selected").click();
    }, 90)
  });

};

tabs();
