// Change between tabs
if ($('.biddings').length > 0 ) {
  
  var $hash = window.location.hash;
  
  if ($hash) {
    
    $('.tab-selected').removeClass('tab-selected');
    $('.tab-active').removeClass('tab-active');
    
    $('.tab-link[href="'+ $hash + '"]').addClass('tab-selected');

    $($hash.replace('!', '')).addClass('tab-active');
    
  }
  
  $(document).on('click', '.tab-link', function() {
    
    var $id = $(this).attr('href').replace('!', ''),
        $element = $($id);
    
    if (!$(this).hasClass('tab-selected')) {
      
      $('.tab-selected').removeClass('tab-selected');
      
      $(this).addClass('tab-selected');
      
    }
    
    if (!$element.hasClass('tab-active')) {
      
      $('.tab-active').removeClass('tab-active');
      
      $($id).addClass('tab-active');
      
    }
  
  });
  
}