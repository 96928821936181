$(document).ready(function () {
    $('.simple-image-upload').each(function(el) {
        const $simpleUpload = $(this);
        const formId = $simpleUpload.attr('id');
        var fileInput;
        var avatarTarget;

        $simpleUpload.find('.trigger-input').click(function(){
             $('input[type="file"]').trigger('click');
        });

        if ($simpleUpload.hasClass('remote-input') && $simpleUpload.attr('data-target')) {
            fileInput = $($simpleUpload.attr('data-target'));
        } else {
            fileInput = $simpleUpload.find(':file');
        }

        if ($simpleUpload.hasClass('remote-input') && $simpleUpload.attr('data-avatar-target')) {
            avatarTarget = $($simpleUpload.attr('data-avatar-target'));
        } else {
            avatarTarget = $simpleUpload.find('.image-target');
        }

        if (fileInput.length) {
            fileInput.on('change', function() {
                var file = this.files[0];

                // Send the data to the API
                if ($simpleUpload.attr('action')) {
                    window.axios.request({
                        url: $simpleUpload.attr('action'),
                        method: 'post',
                        data:  new FormData($simpleUpload[0]),
                    })
                    .then(function (response) {
                        // Lets update the photo
                        if (avatarTarget.is('img')) {
                            avatarTarget.attr('src', response.data.media._links.thumb);
                        } else {
                            avatarTarget.css(
                                'background-image',
                                'url(' + response.data.media._links.thumb + ')'
                            );
                        }
                        // window.toastr.success(response.message || 'Success');
                    })
                    .catch(function (error) {
                        // IF an error ocurred lets remove the file from the input
                       fileInput.val('');

                        // The response out of the 2xx statuses
                        if (error.response) {
                            const response = error.response;
                            if (response.status == 422) {
                                var errors = response.data;

                                for (var field in errors) {
                                    if (errors.hasOwnProperty(field)) {
                                        $errorInput = $simpleUpload.find('[name="' + field +'"]');
                                        $errorInput.addClass('has-error');

                                        $formGroup = $errorInput.parent('.form-group');
                                        $formGroup.addClass('has-error');

                                        if (errors[field].prop && errors[field].prop.constructor === Array) {
                                            var messages = '<ul>';

                                            for (var i = 0; i < errors[field].length; i++) {
                                                messages += '<li>' + errors[field] + '</li>'
                                            }
                                            messages += '</ul>'

                                            $formGroup.find('.help-block').html(messages);
                                        } else {
                                            $formGroup.find('.help-block').text(errors[field]);
                                        }
                                    }
                                }
                            }
                        } else if (error.request) {
                            window.toastr.error(error.request.message || error.message);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            window.toastr.error(error.message);
                        }
                    });
                }
            });
        }
    })
})
